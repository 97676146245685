import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { OrgUsageSumamry as OrgUsageSummary } from '../../components/account/platformVideoUsage/service';
import {
  AltSignup,
  Booth,
  EventInterface,
  EventRegistration,
  MailReport,
  menuSection,
  PayoutInformation,
  PrivilegeRequestPopulated,
  PrivilegeTypes,
  WelcomeVideo,
} from '../../interfaces/interfaces';
import {
  getAllAltSignups,
  getAllEvents,
  getBooth,
  GetBoothBodyParams,
  getBoothsBySlug,
  getEvent,
  GetEventBodyParams,
  getEventPrivilegeRequests,
  getEventPrivilegeRequestsByOrg,
  getMailReport,
  getPayoutInfo,
} from '../../services/services';

export interface AdminState {
  userbooth: Booth | null;
  selectedEventId: any;
  event: EventInterface | null;
  mailReport: MailReport[];
  usercount: any;
  mobileMenuOpen: boolean;
  checkActivities: any;
  requiredPrivilege: keyof typeof PrivilegeTypes | undefined;
  templateEvents: EventInterface[];
  privilegeRequests: PrivilegeRequestPopulated[];
  booths: Booth[];
  payoutInformation?: PayoutInformation;
  eventMenuSections?: menuSection[];
  registrations: EventRegistration[];
  guestsTotal: number;
  isLoadingTotal: boolean;
  guestsPage: number;
  currentViewId?: string;
  promotion?: {
    version: string;
    fileId: string;
  };
  altSignups: AltSignup[];
  showPlatformUsageModal: boolean;
  platformUsage?: OrgUsageSummary;
  showBudgetWarning?: boolean;
  showPremiumFunctions?: boolean;
  isBoothEditMode: boolean;
  currentBoothTab: 'booth' | 'leads' | 'analytics' | 'exhibitorinfo' | 'filters' | 'autoreply' | 'configuration' | 'meetingpartner' | 'faq';
  blockUserModal?: {
    userId: string;
    meetingId?: string;
    onConfirm?: () => void;
  };
}

const initialState: AdminState = {
  userbooth: null,
  selectedEventId: null,
  event: null,
  mailReport: [],
  usercount: {},
  mobileMenuOpen: false,
  checkActivities: null,
  requiredPrivilege: undefined,
  templateEvents: [],
  privilegeRequests: [],
  booths: [],
  payoutInformation: undefined,
  eventMenuSections: [],
  registrations: [],
  guestsTotal: 0,
  isLoadingTotal: true,
  guestsPage: 0,
  altSignups: [],
  platformUsage: undefined,
  showPlatformUsageModal: false,
  showBudgetWarning: false,
  showPremiumFunctions: false,
  isBoothEditMode: false,
  currentBoothTab: 'booth',
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetAdmin: (state, action) => {
      state = { ...initialState, showBudgetWarning: state.showBudgetWarning };
    },
    setAdminUserBooth: (state, action: PayloadAction<Booth | null>) => {
      state.userbooth = action.payload;
    },
    setAdminEventID: (state, action) => {
      state.selectedEventId = action.payload;
    },
    setAdminEvent: (state, action) => {
      state.event = action.payload;
    },
    setMailReport: (state, action: PayloadAction<MailReport[]>) => {
      state.mailReport = action.payload;
    },
    setUserCount: (state, action) => {
      state.usercount = action.payload;
    },
    setMobileMenuOpen: (state, action) => {
      state.mobileMenuOpen = action.payload;
    },
    setAdminBoothVideos: (state, action: PayloadAction<WelcomeVideo[]>) => {
      if (!state.userbooth) return;
      state.userbooth.boothVideos = action.payload;
    },
    setCheckActivities: (state, action: PayloadAction<any>) => {
      state.checkActivities = action.payload;
    },
    setRequiredPrivilege: (state, action: PayloadAction<any>) => {
      state.requiredPrivilege = action.payload;
    },
    setTemplateEvents: (state, action: PayloadAction<EventInterface[]>) => {
      state.templateEvents = action.payload;
    },
    setPrivilegeRequests: (state, action: PayloadAction<PrivilegeRequestPopulated[]>) => {
      state.privilegeRequests = action.payload;
    },
    setAdminBooths: (state, action) => {
      state.booths = action.payload;
    },
    setPayoutInformation: (state, action) => {
      state.payoutInformation = action.payload;
    },
    setEventMenuSections: (state, action) => {
      state.eventMenuSections = action.payload;
    },
    setRegistrations: (state, action) => {
      state.registrations = action.payload;
    },
    setGuestsTotal: (state, action) => {
      state.guestsTotal = action.payload;
    },
    setIsLoadingTotal: (state, action) => {
      state.isLoadingTotal = action.payload;
    },
    setGuestsPage: (state, action) => {
      state.guestsPage = action.payload;
    },
    setCurrentViewId: (state, action) => {
      state.currentViewId = action.payload;
    },
    setPromotion: (state, action) => {
      state.promotion = action.payload;
    },
    setAltSignups: (state, action) => {
      state.altSignups = action.payload;
    },
    setPlatformUsage: (state, action) => {
      state.platformUsage = action.payload;
    },
    setShowPlatformUsageModal: (state, action) => {
      state.showPlatformUsageModal = action.payload;
    },
    setShowBudgetWarning: (state, action) => {
      state.showBudgetWarning = action.payload;
    },
    setShowPremiumFunctions: (state, action) => {
      state.showPremiumFunctions = action.payload;
    },
    setIsBoothEditMode: (state, action: PayloadAction<boolean>) => {
      state.isBoothEditMode = action.payload;
    },
    setCurrentBoothTab: (state, action) => {
      state.currentBoothTab = action.payload;
    },
    setBlockUserModal: (state, action) => {
      state.blockUserModal = action.payload;
    },
  },
});

export const fetchAndSetAdminEvent = (getEventBodyParams: GetEventBodyParams) => async (dispatch: Dispatch) => {
  const data = await getEvent(getEventBodyParams);
  dispatch(setAdminEvent(data.data));
};

export const fetchAndSetAdminBooth = (getBoothBodyParams: GetBoothBodyParams) => async (dispatch: Dispatch) => {
  const data = await getBooth(getBoothBodyParams);
  dispatch(setAdminUserBooth(data));
  dispatch(setAdminEvent(data.event));
};

export const fetchAndSetMailReport = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getMailReport(eventid);
  dispatch(setMailReport(data.data));
};

export const fetchAndSetTemplateEvents = () => async (dispatch: Dispatch) => {
  const data = await getAllEvents(false, 3000, [], true, { slug: 1, name: 1 }); // may be changed
  dispatch(setTemplateEvents(data));
};

export const fetchAndSetEventPrivilegeRequests = (eventid: string) => async (dispatch: Dispatch) => {
  const data = await getEventPrivilegeRequests(eventid);
  dispatch(setPrivilegeRequests(data));
};

export const fetchAndSetEventPrivilegeRequestsByOrg = (orgId: string) => async (dispatch: Dispatch) => {
  const data = await getEventPrivilegeRequestsByOrg(orgId);
  dispatch(setPrivilegeRequests(data));
};

export const fetchAndSetAdminBooths = (slug: string) => async (dispatch: Dispatch) => {
  const { data } = await getBoothsBySlug(slug);
  dispatch(setAdminBooths(data));
};

export const fetchAndSetPayoutInformation = (eventId: string) => async (dispatch: Dispatch) => {
  const { data } = await getPayoutInfo(eventId);
  dispatch(setPayoutInformation(data));
};

export const fetchAndSetAltSignups = (eventId: string) => async (dispatch: Dispatch) => {
  const data = await getAllAltSignups(eventId);
  dispatch(setAltSignups(data.data));
};

export const {
  resetAdmin,
  setAdminUserBooth,
  setAdminEventID,
  setAdminEvent,
  setMailReport,
  setUserCount,
  setMobileMenuOpen,
  setAdminBoothVideos,
  setCheckActivities,
  setRequiredPrivilege,
  setTemplateEvents,
  setPrivilegeRequests,
  setAdminBooths,
  setPayoutInformation,
  setEventMenuSections,
  setRegistrations,
  setGuestsTotal,
  setIsLoadingTotal,
  setGuestsPage,
  setCurrentViewId,
  setPromotion,
  setPlatformUsage,
  setAltSignups,
  setShowPlatformUsageModal,
  setShowBudgetWarning,
  setShowPremiumFunctions,
  setIsBoothEditMode,
  setCurrentBoothTab,
  setBlockUserModal,
} = adminSlice.actions;

export default adminSlice.reducer;
