import { ErrorMessage } from '@hookform/error-message';
import { Alert } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from '../../reduxToolkit/typedSelector';
import { login } from '../../utils/auth';
import { LinkWithA } from '../common/LinkWithA';
import VyButton from '../common/VyButton';
import VyTextField from '../common/VyTextField';
import { ChevronRight } from '@mui/icons-material';

declare const window: any;
interface SignInFormInterface {
  withRedirect?: boolean;
  query?: { email: string; rurl: string; redirect: string };
  classes?: { form: string; textCenter: string; submit: string };
  onSuccess?: () => void;
  showCreateAccount?: boolean;
  desiredRole?: string;
}

interface FormProps {
  email: string;
  password: string;
}

const SignInForm: React.FC<SignInFormInterface> = ({ withRedirect = false, query, classes, onSuccess, showCreateAccount = true, desiredRole }) => {
  const { i18n, t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormProps>();
  const router = useRouter();
  const presetMail = useSelector(({ common }) => common.signInModalMail);

  const [msg, setMsg] = useState('');

  function redirect(level: number) {
    if (!router.query.rurl) {
      if (level === 0) {
        toast('Das Adminpanel ist jetzt über admin.vystem.io erreichbar.');
      }
      return router.push(router.query.redirect ? (router.query.redirect as string) : '/account');
    } else {
      // for external urls window.location is better. see here: https://nextjs.org/docs/api-reference/next/router
      window.location = `${query?.rurl}${query?.redirect ? `?redirect=${query.redirect}` : ''}`;
    }
  }

  async function submit(data?: FormProps) {
    const body = {
      ...data,
      email: data?.email.toLowerCase(),
      desiredRole,
    };

    try {
      const res: any = await axios.post('/auth/login', body);
      const { role, access_token } = res.data.data;
      login(access_token, role.level);
      withRedirect && redirect(role.level);
      onSuccess?.();
    } catch (err) {
      console.error(err);
      setMsg((err as any)?.response?.data?.translation?.[`${i18n.language}-${i18n.language?.toUpperCase()}`] || t('common:error-without-status'));
    }
  }

  return (
    <form className={classes?.form ?? 'w-full mt-2'} onSubmit={handleSubmit(submit)} noValidate>
      {msg && (
        <Alert severity="error" onClose={() => setMsg('')}>
          {msg}
        </Alert>
      )}
      <Controller
        control={control}
        name={`email`}
        rules={{
          required: 'Dieses Feld ist verpflichtend.',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Ungültige E-Mail Adresse',
          },
        }}
        defaultValue={presetMail ?? query?.email}
        render={({ field }) => (
          <VyTextField
            {...field}
            errorText={errors.email?.message?.toString()}
            disabled={!!presetMail}
            margin="normal"
            fullWidth
            id="email"
            label={t('auth:email')}
            type="email"
            autoComplete="email"
            color="secondary"
            autoFocus
          />
        )}
      />
      <Controller
        control={control}
        name={`password`}
        render={({ field }) => (
          <VyTextField
            {...field}
            margin="normal"
            fullWidth
            label={t('auth:password')}
            type="password"
            id="password"
            color="secondary"
            autoComplete="current-password"
            errorText={errors.password?.message?.toString()}
          />
        )}
      />
      <VyButton className="w-full mt-2 mb-6" id="login" type="submit" endIcon={<ChevronRight className="animate-pulse" />}>
        {t('auth:login')}
      </VyButton>
      <div className="flex flex-row w-full justify-between">
        {showCreateAccount && (
          <LinkWithA className="text-gray-500 hover:text-gray-800 transition duration-300" href="/signup" as="/signup">
            {t('auth:create-account')}
          </LinkWithA>
        )}
        <LinkWithA className="text-gray-500 hover:text-gray-800 transition duration-300" href="/new_password" as="/new_password">
          {t('auth:recover-password')}
        </LinkWithA>
      </div>
    </form>
  );
};
export default SignInForm;
