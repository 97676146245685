import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Check } from '@mui/icons-material';
import { StandardTextFieldProps, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { SyncLoader } from 'react-spinners';

export interface VyTextFieldProps extends StandardTextFieldProps {
  loading?: boolean;
  errorText?: string;
  value?: string | number;
  defaultValue?: string | number;
  noarrows?: boolean;
  borderColor?: string;
  labelColor?: string;
  showError?: boolean;
  showLength?: boolean;
  register?: any;
  sxProp?: any;
  preventDefault?: boolean;
}

const VyTextField: React.FC<VyTextFieldProps> = ({
  loading,
  errorText,
  defaultValue,
  value: propValue,
  noarrows,
  borderColor,
  labelColor,
  showError = true,
  showLength = true,
  ...props
}) => {
  const [valueForCharCount, setValueForCharCount] = useState(defaultValue ?? propValue);
  const [loadingDone, setLoadingDone] = useState<boolean>(false);
  const [hideArrows, setHideArrows] = useState(true);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!loading) {
      setLoadingDone(true);
      setTimeout(function () {
        setLoadingDone(false);
      }, 400);
    }
  }, [loading]);

  useEffect(() => {
    // <style> takes props from server side which is always false initialy
    setHideArrows(noarrows ?? true);
  }, [noarrows]);

  return (
    <>
      <div className={`w-full vy-input-container inline-block relative`}>
        <TextField
          {...props}
          {...props.register}
          onKeyDown={(e) => {
            props.onKeyDown?.(e);
            if (!props.preventDefault && e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          value={propValue}
          fullWidth
          // Have to check for undefined explicitely because somehow 0 is validated as undefined as well
          {...(defaultValue !== undefined || propValue !== undefined ? { defaultValue: defaultValue ?? propValue } : {})}
          onChange={(e) => {
            if (!e.target.value || e.target.value.length <= (props?.inputProps?.maxLength || 10000)) {
              setValueForCharCount(e.target.value);
            }
            if (props.onChange) {
              props.onChange(e);
              return;
            }
          }}
          className={`${props.className}`}
          sx={
            props.sxProp ?? {
              input: {
                color: borderColor,
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: borderColor,
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: borderColor,
                },
                '&:hover fieldset': {
                  borderColor: borderColor,
                },
                '&.Mui-focused fieldset': {
                  borderColor: borderColor,
                },
              },
            }
          }
          InputLabelProps={{
            style: { color: labelColor },
          }}
          variant="outlined"
          size={props.size}
          error={!!props.error}
          helperText={errorText && typeof errorText === 'string' ? errorText : undefined}
        />
        {(loadingDone || loading) && (
          <div className={`absolute top-[2px] ${loadingDone ? 'right-1' : '-right-[15px]'} text-[10px]`}>
            {!loadingDone || loading ? <SyncLoader color="grey" className="scale-[30%]" /> : <FontAwesomeIcon icon={faCheck} />}
          </div>
        )}
        {props?.inputProps?.maxLength && showLength ? (
          <small className="absolute bottom-[3px] right-[5px] text-[10px] text-slate-500">{`${valueForCharCount?.toString().length || 0} / ${
            props?.inputProps?.maxLength
          }`}</small>
        ) : null}
      </div>
      <style jsx global>{`
        .vy-input-container input[type='number']::-webkit-inner-spin-button,
        .vy-input-container input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: ${hideArrows ? 'none' : 'auto'};
          margin: 0;
        }

        .vy-input-container input[type='number'] {
          -moz-appearance: ${hideArrows ? 'textfield' : 'auto'};
        }
      `}</style>
    </>
  );
};

export default VyTextField;
