import { useDispatch } from 'react-redux';
import { useSelector } from '../../reduxToolkit/typedSelector';
import { VyConfirm } from '../common/VyConfirm';
import { setBlockUserModal } from '../../reduxToolkit/slices/adminSlice';

export const GlobalAdminModals = () => {
  const blockUserModal = useSelector((state) => state.admin.blockUserModal);

  const dispatch = useDispatch();

  return (
    <>
      {blockUserModal && <VyConfirm defaultOpen={true} onConfirm={() => blockUserModal.onConfirm?.()} onClose={() => dispatch(setBlockUserModal(undefined))} />}
    </>
  );
};
