import { CircularProgress, Container } from '@mui/material';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { VyBackgroundVideoLoop } from '../../components/common/VyBackgroundVideoLoop';
import VySEO from '../../components/common/VySEO';
import SignInForm from '../../components/signin/SignInForm';
import { AutoLanguageWrapper } from '../../layouts/AutoLanguageWrapper';
import { getLocaleFromStaticContext } from '../../utils/functions';

declare const window: any;

function SignIn() {
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();

  function redirect(level: number) {
    if (!router.query.rurl) {
      if (level === 0) {
        toast('Das Adminpanel ist jetzt über admin.vystem.io erreichbar.');
      }

      console.log('Redirecting to Accountpanel');
      return router.push(!router.asPath.includes('/login') ? router.asPath : '/account');
    } else {
      // for external urls window.location is better. see here: https://nextjs.org/docs/api-reference/next/router
      window.location = `${router.query.rurl}${router.query?.redirect ? `?redirect=${router.query.redirect}` : ''}`;
    }
  }

  function getLevel() {
    const level = localStorage.getItem('role');
    if (level) redirect(parseInt(level));
    else setIsLoading(false);
  }

  useEffect(() => {
    getLevel();
  }, [router]);

  return (
    <AutoLanguageWrapper>
      <VySEO title="Login" noindex={false} />
      <Container component="main" className={` flex flex-col items-center justify-center h-screen`} maxWidth="xs">
        <div className="flex flex-col items-center p-10 bg-white rounded-md shadow-md">
          <img src="/vystemlogo.png" className={`m-1 mb-5 max-w-[40%]`} alt="vystem logo" />
          {isLoading && <CircularProgress />}

          {!isLoading && (
            <>
              {/* <Alert className="w-full" severity="info">
                {t('auth:invite-to-vystem-alert')}{' '}
                <VyTooltip title={t('auth:generate-password-tooltip')}>
                  <LinkWithA style={{ textDecoration: 'underline' }} href="new_password" as="new_password">
                    {t('auth:click-generate-password')}
                  </LinkWithA>
                </VyTooltip>
          </Alert>*/}
              <SignInForm withRedirect={true} query={router.query as any} />
            </>
          )}
        </div>
      </Container>
      <VyBackgroundVideoLoop />
    </AutoLanguageWrapper>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(getLocaleFromStaticContext(context))),
    },
  };
};

export default SignIn;
